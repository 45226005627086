import React from "react"
import { Link } from "gatsby"
import layoutStyles from "../layout.module.css"

export default function PRO003Overview(props) {
    return(
    <table cellPadding="0" border="0">
      <tr>
      <td> <Link to="../../PRO003/PRO003_ServoRange" style={{ textDecoration:"none" ,textShadow: `none`, backgroundImage: `none` }}>Increasing servo range</Link></td>
      <td >November 14th 2020</td>
    </tr>
     {/*<tr>
      <td> <Link to="../../PRO003/PRO003_VehicleDynamics" style={{ textDecoration:"none" ,textShadow: `none`, backgroundImage: `none` }}>Adding vehicle dynamics</Link></td>
      <td >February 16th 2021</td>
     </tr>*/}
    </table>
)}