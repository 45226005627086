import React, {Component} from "react"
import { Link } from "gatsby"

const ListLink = props => (
  <li style={{ backgroundColor:'white', marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

class DropdownFlukes extends Component{ 
      
  constructor() {
    super();
    
    this.state = {
      showMenu: false,
    };
    
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  
  showMenu(event) {
    event.preventDefault();

    this.setState({
      showMenu: true
    });

    window.addEventListener('click', this.closeMenu,true);
  }
  
  closeMenu(event) {
    if (!this.dropdownMenu.contains(event.target)) {
      this.setState({
        showMenu: false
      });}
      
      window.removeEventListener('click', this.closeMenu,true);
  }
  
  render(){
        return (
        
        <li style={{ backgroundColor:'white', display: `inline-block`, marginRight: `1rem` }}> 
          <a style={{display:'inline'}} onClick={this.showMenu}>Strays</a>
        
        {
          this.state.showMenu
          ? (
            
              <ul 
              className="menu"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
              style={{ marginLeft:'-2.8em',marginTop:'2em', listStyle: `none`, float: `right` }}>
              <ListLink to="/sharebib/">Trade Libs</ListLink>
              <ListLink to="/geweestengoedgekeurd/">GEG</ListLink>
             </ul> 
            
            )
            : (
              null
            )
        }
      </li>

    )}}
    export default DropdownFlukes