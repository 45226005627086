import React from "react"
import { Link } from "gatsby"
import avatar_PRO001 from "../Figures/PRO003_avatar.jpg"
import layoutStyles from "./indexLayout.module.css"
import Overviewtable from "../components/ProjectOverviews/PRO003Overview.js"


export default function TarmoPrev() {
  return (
    <div className={layoutStyles.top}>
    <div>
    <table cellPadding="0" border="0" className={layoutStyles.table} style={{}}>
      <tr>
        <td rowSpan="2" style={{marginBottom:"0", width:"30%"}}><img  src={avatar_PRO001} style={{borderRadius: 20, marginBottom:"0"}} alt="avatar PRO001" /></td>
        <th  align="left" style={{marginBottom:"0"}}>PRO003 - RC car updates </th>
      </tr>
      <tr>
        <td  align="left" style={{marginBottom:0}}>Status: &#11035;&#11036;&#11036;&#11036; </td>
      </tr>
      <tr>
        <td colSpan="2">First pandamic lockdown I bought myself a 3D printer and printed a RC car (the <a href="https://docs.google.com/document/d/1hfrpDU1DQm6QKrioJsxxcMdq8ormYTlwAHaiswDuSHI/edit">Tarmo4</a> by Engineering Nonsense), to have an actual project to work on,and I'll try to upgrade performance and add some vehicle dynamics.</td>
      </tr>
      </table>
      </div>
      <div className={layoutStyles.table}>
        <Overviewtable ></Overviewtable>
      </div>
      </div>
  )
}