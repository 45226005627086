import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import background from "../../Figures/PRO003_banner.jpg"
import pageLayout from "../pages.module.css"
import ProjectOverview from "../../components/ProjectOverviews/PRO003Overview.js"
import TarmoPrev from "../../components/TarmoPrev"
import ServoRange from "../../Figures/ServoRange.png"
import SteeringServo from "../../Figures/SteeringServo.jpg"
import WheelRotation from "../../Figures/WheelRotatingRange.jpg"



export default function PRO003_ServoRange() {
  return (
      <div>
      <div>
    <Layout>
    </Layout>
    <div  style={{backgroundImage: "url(" + background + ")" ,backgroundSize: "cover", minHeight: "150px",zIndex:"1"}}>
      <div className={pageLayout.text}>
      <h1 style={{color: "white"}}>PRO003 - RC car tweaking</h1>
      <br />
      <h2 style={{color: "white"}}>Adding vehicle dynamics</h2>
        </div>
      </div>
      </div>
      <div style={{}}>
      <div className={pageLayout.text}>
        <h2>Vehicle Dynamics</h2>
        <p>Driving the <a href="https://docs.google.com/document/d/1hfrpDU1DQm6QKrioJsxxcMdq8ormYTlwAHaiswDuSHI/edit">Tarmo4</a> is great. It is a lot faster than I had expected and driving it made me happy like a child. The amount of power and my (lack of) driving skills makes it difficult to turn at high speeds without the the car oversteering. In the RC world this is commonly solved by adding a <a href="https://www.amazon.com/SKYRC-Automatic-Stability-Adjusting-Steering/dp/B08F3DCY21/ref=sr_1_22?crid=3R1SO4I9NZVX6&dchild=1&keywords=rc+car+gyro+stabilizer&qid=1613422148&sprefix=RC+car+gyro+s%2Caps%2C223&sr=8-22">RC gyro</a>.
        <br /> </p>

        </div>
      <div className={pageLayout.side}>
        <h2 style={{}}>Project overview:</h2>
      <ProjectOverview></ProjectOverview>
      </div>
      </div>
    </div>
  )
}