import React from "react"
import { Link } from "gatsby"
import layoutStyles from "./layout.module.css"
import DropdownFlukes from "./DropdownFlukes.js"

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)


export default function Layout({ children }) {
  return (
    <div className={layoutStyles.top} >
      <header style={{ marginBottom: `1.5rem` }}>
        <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
          <h3 style={{ display: `inline` }}>Belgian tinker</h3>
        </Link>
        <ul style={{ display:'inline', listStyle: `none`, float: `right` }}>
          <ListLink to="/">Home</ListLink>
          {/*<ListLink to="/about/">About</ListLink>*/}
          <DropdownFlukes/>
          <ListLink to="/contact/">Contact</ListLink>
          
        </ul>
        
        </header>
      {children}
    </div>

  )
}